import { ClipboardEvent, createRef, RefObject, useEffect, useRef, useState } from "react"
import classNames from "classnames"
import { twMerge } from "tailwind-merge"

interface PasscodeInputProps {
  length?: number
  onBlur?: () => void
  value?: string
  onChange?: (value: string) => void
  haveError?: boolean
  className?: string
}

const PasscodeInput = ({ length = 6, value, onBlur, onChange, haveError, className }: PasscodeInputProps) => {
  const arrayFromLength = Array.from(Array(length).keys())
  const passcodeRef = useRef<RefObject<HTMLInputElement>[]>([])
  passcodeRef.current = arrayFromLength.map((_, i) => passcodeRef.current[i] ?? createRef())
  const blurRef = useRef(false)

  const [focusedInput, setFocusedInput] = useState<number | null>(null)
  const focusedInputIndexRef = useRef<number | null>(null)

  const focusNextRef = (index: number) => {
    const next = index + 1
    if (!passcodeRef.current[next]) {
      if (!!passcodeRef.current[length - 1].current) {
        passcodeRef.current[length - 1].current!.focus()
        passcodeRef.current[length - 1].current!.select()
      }
      return
    }
    passcodeRef.current[next].current!.focus()
    passcodeRef.current[next].current!.select()
  }

  const updateValue = () => {
    const valueInput = Array.from({ length })
      .map((_, index) => (!!passcodeRef.current[index]?.current ? passcodeRef.current[index].current!.value : ""))
      .join("")

    onChange && onChange(valueInput)

    if (valueInput.length === length) {
      blurRef.current = true
    }

    if (blurRef.current) {
      onBlur && onBlur()
    }
  }

  const focusPreviousRef = (index: number) => {
    const previous = index - 1

    if (index === length - 1) {
      passcodeRef.current[index].current!.focus()
      passcodeRef.current[index].current!.select()
    }
    if (!!passcodeRef.current[previous]?.current) {
      passcodeRef.current[previous].current!.focus()
      passcodeRef.current[previous].current!.select()
    }
    updateValue()
  }

  const handleInputChange = (target: EventTarget & HTMLInputElement, index: number) => {
    //check value is number
    if (target.value !== " " && isNaN(Number(target.value))) {
      return
    }
    const value = target.value.match(/\d/g) || " "

    if (value === " ") {
      target.value = " "
      return focusPreviousRef(index)
    }

    if (!value || !value.length) {
      target.value = ""
      return
    }
    target.value = value[value.length - 1]
    updateValue()
    focusNextRef(index)
  }

  const handlePaste = (e: ClipboardEvent<HTMLDivElement>) => {
    e.preventDefault()

    const focusIndex = focusedInputIndexRef.current || 0

    const text = e.clipboardData.getData("Text").match(/\d/g)
    if (!text || !text.length) return

    text.forEach((item: string, index: number) => {
      if (!!passcodeRef.current[index + focusIndex]?.current) {
        passcodeRef.current[index + focusIndex].current!.value = item.toString()
      }
    })

    updateValue()
  }

  const handleMoveFocus = (index: number) => {
    if (focusedInputIndexRef.current !== index) {
      focusedInputIndexRef.current = index
      setFocusedInput(index)
    }
  }

  const firstTime = useRef(true)

  useEffect(() => {
    if (focusedInput === null) {
      if (firstTime.current) {
        firstTime.current = false
      } else {
        onBlur && onBlur()
        blurRef.current = true
        firstTime.current = false
      }
    }
  }, [focusedInput])

  return (
    <div className="relative w-full">
      <div className="flex w-full space-x-2 overflow-hidden" onPaste={e => handlePaste(e)}>
        {arrayFromLength.map((_, index) => (
          <input
            value={value?.[index] || ""}
            key={index}
            ref={passcodeRef.current[index]}
            className={twMerge(
              classNames(
                "h-10 text-center w-full bg-atherGray-900 text-xl font-semibold text-atherGray-0 rounded-none border-atherGray-800 border-b-[2px] flex-1 outline-none",
                {
                  "bg-red-500": !!haveError,
                },
              ),
              className,
            )}
            type="number"
            pattern="[0-9]*"
            inputMode="numeric"
            min="0"
            autoComplete="off"
            onBlur={() => {
              setTimeout(() => {
                if (focusedInputIndexRef.current === index) {
                  focusedInputIndexRef.current = null
                  setFocusedInput(null)
                }
              }, 100)
            }}
            onFocus={() => {
              focusedInputIndexRef.current = index
              setFocusedInput(index)
            }}
            onChange={e => handleInputChange(e.target, index)}
            onKeyDown={e => {
              // focus next input when enter the same key
              if (e.key === e.currentTarget.value) {
                e.preventDefault()
                focusNextRef(index)
                return
              }
              if (e.key === "Backspace") {
                if (e.currentTarget.value === "") {
                  e.preventDefault()
                  focusPreviousRef(index)
                } else {
                  ;(e.target as any)?.value || focusPreviousRef(index)
                }
              }

              if (e.key === "ArrowLeft") {
                handleMoveFocus(Math.max(0, index - 1))
                if (Math.max(0, index - 1) < index) {
                  e.preventDefault()
                  focusPreviousRef(index)
                }
              }

              if (e.key === "ArrowRight") {
                handleMoveFocus(Math.min(length - 1, index + 1))
                if (Math.min(length - 1, index + 1) > index) {
                  e.preventDefault()
                  focusNextRef(index)
                }
              }
            }}
          />
        ))}
      </div>
    </div>
  )
}

export default PasscodeInput
