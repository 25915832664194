const emailRegex =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}\(\)?\-\"!@#%&\/,><\':;|_~`])\S{8,99}$/

export const isEmailAddress = (email: string) => emailRegex.test(email)

export const isTelegramEmail = (email: string): boolean => {
  return /^[a-zA-Z0-9._%+-]+@(telegram\.org)$/.test(email)
}

export const isAcceptPassword = (email: string): boolean => {
  return passwordRegex.test(email)
}
