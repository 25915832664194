import client from "@/api/client"
import { FarcasterAuthorizeRequest } from "@/api/sdk"
import IconButton from "@/components/IconButton"
import Input from "@/components/Input"
import Modal from "@/components/Modal"
import PasscodeInput from "@/components/Setting/Security/PasscodeInput"
import { EyeCloseIcon, EyeIcon } from "@/components/shared/icons"
import { cdnPublicFolderUrl, cdnUrl } from "@/constants"
import { useToast } from "@/hooks"
import { useClearUrlQuery } from "@/hooks/useQuery"
import { auth } from "@/lib/firebaseClient"
import { googleAnalytics } from "@/lib/gtag"
import { useSignInStore } from "@/stores"
import { cn } from "@/utils/cn"
import { isAcceptPassword, isEmailAddress } from "@/utils/is-email-address"
import { useMutation } from "@tanstack/react-query"
import { useWeb3ModalState } from "@web3modal/wagmi/react"
import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth"
import Link from "next/link"
import useCustomRouter from "@/hooks/useCustomRouter"
import { Fragment, useCallback, useEffect, useState } from "react"
import { FormProvider, useForm, useWatch } from "react-hook-form"
import SignInWithThirdParty from "../SignInModalV2/SignInWithThirdParty"
import LinkAccount from "./LinkAccount"
import LinkAccountEvm from "./LinkAccountEvm"
import LinkFarcaster from "./LinkFarcaster"

export type SignUpFormType = {
  email: string
  password?: string
  passcode?: string
}

const SignUpModalV2 = ({ onClose: onCloseOutside }: { onClose?: () => void }) => {
  const [showPassword, setShowPassword] = useState(false)
  const router = useCustomRouter()
  const clearUrl = useClearUrlQuery()
  const [isLoading, setIsLoading] = useState(false)
  const { setSignInModal, signInModal } = useSignInStore()

  const isOpen = (router.query.signUp === "true" || signInModal.signUp) ?? false
  const [mode, setMode] = useState<"sign-up" | "verify-email" | "link-wallet" | "link-wallet-evm" | "link-farcaster">(
    "sign-up",
  )
  const [farcasterPayload, setFarcasterPayload] = useState<FarcasterAuthorizeRequest | null>(null)
  const toast = useToast()

  const form = useForm<SignUpFormType>({
    mode: "onSubmit",
  })

  const {
    setError,
    setValue,
    getValues,
    clearErrors,
    reset,
    register,
    formState: { errors },
  } = form

  const { passcode, password, email } = useWatch({
    control: form.control,
  })

  const onClose = useCallback(() => {
    document.body.style.overflow = "unset"

    setSignInModal({
      signUp: false,
    })
    setMode("sign-up")
    setShowPassword(false)
    clearErrors()
    reset()
    if (router.query.signUp === "true") {
      clearUrl(["signUp"])
    }
  }, [clearUrl, reset, setSignInModal])

  const { mutate: mutateSendSignUpEmailConfirmation, isPending: isSending } = useMutation({
    mutationFn: ({ email }: { email: string; isVerify?: boolean }) =>
      client.api.authControllerSendSignUpEmailConfirmation({
        email,
      }),
    onSuccess: (_, { isVerify }) => {
      if (isVerify) {
        setIsLoading(false)
        setMode("verify-email")
        return
      }

      toast({
        status: "success",
        title: "Success",
        message: ["Email sent"],
      })
    },
    onError: (error: any) => {
      toast({
        status: "error",
        title: "Error",
        message: [error?.message ?? "Error sending email confirmation"],
      })
    },
  })

  const { mutate: mutateVerifySignUpEmailConfirmation, isPending: isConfirm } = useMutation({
    mutationFn: (data: SignUpFormType) =>
      client.api.authControllerVerifySignUpEmailConfirmation({
        email: data.email,
        code: data.passcode ?? "",
      }),
    onSuccess: () => {
      setIsLoading(false)
      toast({
        status: "success",
        title: "Success",
        message: ["Your email has been verified"],
      })

      reset()

      if (router.query.signUp === "true") {
        router.replace(
          {
            query: {
              signIn: true,
              next: router.query.next,
            },
          },
          undefined,
          {
            shallow: true,
          },
        )

        return
      }

      setMode("sign-up")

      setSignInModal({
        signUp: false,
        signIn: true,
      })
    },
    onError: (error: any) => {
      setIsLoading(false)
      toast({
        status: "error",
        title: "Error",
        message: [error?.message],
      })
    },
  })

  const handeResendCodeSignUp = async (isVerify?: boolean) => {
    const { email } = getValues()

    if (!email) {
      toast({
        status: "error",
        title: "Error",
        message: ["Email is required"],
      })

      return
    }

    googleAnalytics.event({
      action: "click",
      label: "send_code",
      category: "sign_up",
    })

    await mutateSendSignUpEmailConfirmation({
      email,
      isVerify,
    })
  }

  const { mutate: mutateCheckStatus } = useMutation({
    mutationFn: (email: string) => client.api.authControllerGetEmailVerifiedStatus(email).then(res => res.data),
    onSuccess: data => {
      setIsLoading(false)

      if (!data.emailVerified) {
        handeResendCodeSignUp(true)

        return
      }

      toast({
        status: "error",
        title: "Your email has been existed",
        message: ["Please try another email."],
      })
    },
    onError: error => {
      setIsLoading(false)
    },
  })

  const signUpWithEmailPassword = async (data: SignUpFormType) => {
    if (!data.email || !data.password) return

    googleAnalytics.event({
      action: "click",
      label: "sign_up_email",
      category: "sign_up",
      params: {
        email: data.email ?? "",
      },
    })

    try {
      const { email, password } = data
      const result = await createUserWithEmailAndPassword(auth, email, password)

      if (result.user) {
        await updateProfile(result.user, {
          displayName: email.split("@")[0],
          photoURL: `https://${cdnUrl}/resources/default-avatar.png`,
        })
      }

      auth.signOut()
      await handeResendCodeSignUp(true)
    } catch (error: any) {
      if (error?.message === "Firebase: Error (auth/email-already-in-use).") {
        mutateCheckStatus(data.email)

        return
      }
      setIsLoading(false)
      console.error("Error signing up with email and password", error)
    }
  }

  const submit = async e => {
    e.preventDefault()
    const data = getValues()
    const trigger = await form.trigger()
    let isValidate = true
    clearErrors()
    googleAnalytics.event({
      action: "click",
      label: "sign_up_mode",
      category: "sign_up",
      params: {
        email: data.email ?? "",
        mode: mode,
      },
    })

    if (mode === "sign-up") {
      //regex email
      if (!isEmailAddress(data.email)) {
        setError("email", {
          message: "Invalid email address",
        })
        isValidate = false
      }

      if (data.password && !isAcceptPassword(data.password)) {
        setError("password", {
          message:
            "Password must contain at least 8 characters, including uppercase, lowercase, numbers, and special characters",
        })
        isValidate = false
      }

      if (!trigger || !isValidate) return

      setIsLoading(true)

      signUpWithEmailPassword(data)

      return
    }

    if (!data.passcode || data.passcode.length < 6) return
    setIsLoading(true)

    mutateVerifySignUpEmailConfirmation(data)
  }

  useEffect(() => {
    clearErrors()
  }, [mode])

  const isDisabled = mode === "verify-email" ? !passcode || passcode.length < 6 : !password || !email

  const renderForm = () => {
    switch (mode) {
      case "verify-email":
        return (
          <Fragment>
            <div>
              <h3 className="text-xl text-center font-semibold mb-6">Verify your account</h3>
              <div>
                <p className="text-xs text-atherGray-500">Enter the 6-digit code we sent to your email</p>
                <div className="flex flex-col h-full md:flex-row items-center">
                  <PasscodeInput
                    value={passcode}
                    onChange={v => setValue("passcode", v)}
                    className="w-8 h-8 text-xl text-atherGray-0 bg-atherGray-900 rounded-none border-atherGray-800 border-b-[2px]"
                  />
                  <div className="flex items-center justify-center h-10 w-[10rem] mt-4 md:mt-0 md:ml-2 cursor-pointer">
                    <IconButton
                      isLoading={isSending}
                      colorScheme="transparent"
                      className="text-atherPurple-300 p-0 bg-atherGray-900 text-sm font-semibold"
                      onClick={e => {
                        e.preventDefault()
                        e.stopPropagation()
                        handeResendCodeSignUp()
                      }}
                    >
                      Resend Code
                    </IconButton>
                  </div>
                </div>
                {errors.passcode && (
                  <p className="text-[0.65rem] text-red-500 leading-4 mt-1">{errors.passcode.message}</p>
                )}
              </div>
              <div className="mt-8">
                <IconButton
                  isLoading={isConfirm}
                  disabled={isDisabled}
                  type="submit"
                  className="w-full  py-3 rounded-xl"
                >
                  Complete
                </IconButton>
              </div>
            </div>
          </Fragment>
        )

      case "link-wallet":
        return <LinkAccount onChangeMode={() => setMode("sign-up")} isSignUp />

      case "link-wallet-evm":
        return <LinkAccountEvm onChangeMode={() => setMode("sign-up")} isSignUp />

      case "link-farcaster":
        return <LinkFarcaster onChangeMode={() => setMode("sign-up")} isSignUp payload={farcasterPayload} />

      default:
        return (
          <Fragment>
            <div>
              <h3 className="text-xl text-center font-semibold mb-6">Sign Up</h3>
              <div className="space-y-4 w-full">
                <div>
                  <Input
                    {...register("email")}
                    placeholder="Email address"
                    className="placeholder:text-atherGray-500"
                  />
                  {errors.email && <p className="text-[0.65rem] text-red-500 mt-1 leading-4">{errors.email.message}</p>}
                </div>
                <div>
                  <Input
                    {...register("password")}
                    rightIcon={
                      <button
                        className={cn("", {
                          "text-atherGray-500": !password,
                          "text-atherGray-0": password,
                        })}
                        type="button"
                        onClick={() => setShowPassword(prev => !prev)}
                      >
                        {showPassword ? <EyeIcon /> : <EyeCloseIcon />}
                      </button>
                    }
                    placeholder="Password"
                    className="placeholder:text-atherGray-500"
                    type={showPassword ? "text" : "password"}
                    autoComplete="new-password"
                  />
                  {errors.password && (
                    <p className="text-[0.65rem] text-red-500 leading-4 mt-1">{errors.password.message}</p>
                  )}
                </div>
              </div>
              <div className="mt-8">
                <IconButton
                  isLoading={isLoading}
                  disabled={isDisabled}
                  type="submit"
                  className="w-full py-3 rounded-xl"
                >
                  Sign Up
                </IconButton>
              </div>
              <p className="mt-4 text-[0.65rem] leading-4 text-atherGray-500 text-center">
                By continuing, you are agreeing to GAIA&apos;s
                <Link className="font-semidbold text-[#D9D9D9]" target="_blank" href="/terms-of-service">
                  {" "}
                  Terms Of Services
                </Link>{" "}
                and{" "}
                <Link className="font-semidbold text-[#D9D9D9]" target="_blank" href="/privacy-policy">
                  Privacy Policy
                </Link>{" "}
              </p>
            </div>

            <div className="flex-1 flex flex-col justify-end">
              <SignInWithThirdParty
                isSignUp
                onClose={() => {
                  onClose()
                }}
                onChangeLinkWallet={(mode = "link-wallet") => setMode(mode)}
                onChangeLinkFarcaster={payload => {
                  setFarcasterPayload(payload)
                  setMode("link-farcaster")
                }}
              />
              <hr className="my-4 border-atherGray-800" />
              <div className="text-center">
                <p className="text-atherGray-500 text-xs">
                  Already have an account?{" "}
                  <Link
                    href={{
                      query: {
                        next: router.query.next,
                        signIn: true,
                      },
                    }}
                    shallow
                    onClick={e => {
                      e.preventDefault()

                      clearErrors()
                      reset()

                      if (router.query.signUp === "true") {
                        router.replace(
                          {
                            query: {
                              next: router.query.next,
                              signIn: true,
                            },
                          },
                          undefined,
                          {
                            shallow: true,
                          },
                        )
                        return
                      }

                      setSignInModal({
                        signUp: false,
                        signIn: true,
                      })
                    }}
                    className="font-semibold text-atherGray-0"
                  >
                    Sign In
                  </Link>
                </p>
              </div>
            </div>
          </Fragment>
        )
    }
  }

  const { open: openWalletModal } = useWeb3ModalState()

  return (
    <Modal
      isDisabledRemoveScroll={openWalletModal}
      closeButtonClassName="absolute top-2 bg-blackAlpha-600 right-2 z-[1] text-white"
      className="bg-[#191918] rounded-xl max-w-[60rem] h-auto shadow-md p-0 modal-content-child"
      bodyClassName="p-0 justify-center"
      containerClassName={"overflow-auto p-4"}
      isOpen={isOpen}
      onClose={() => {
        onCloseOutside?.()
        onClose()
      }}
    >
      <div className="flex flex-col md:flex-row">
        <div className="flex-1 hidden md:flex relative">
          <img className="object-cover h-full m-auto" src={`${cdnPublicFolderUrl}/images/banner.png`} alt="Banner" />
          <div
            className="absolute top-0 left-0 w-full h-[200px]"
            style={{
              background: "linear-gradient(180deg, rgba(0, 0, 0, 0.60) 0%, rgba(0, 0, 0, 0.00) 100%)",
            }}
          />
          <div className="max-w-[10rem] mb-12 absolute top-8 left-8">
            <img src={`${cdnPublicFolderUrl}/logo.png`} alt="Ather Logo" width={88.44} height={44} />
          </div>
        </div>
        <div className="flex-1 flex flex-col overflow-hidden">
          <FormProvider {...form}>
            <form className="flex flex-col flex-1" id="sign-up" onSubmit={submit}>
              <div className="py-6 px-6 md:py-8 md:px-14 w-full flex-1 flex flex-col">{renderForm()}</div>
            </form>
          </FormProvider>
        </div>
      </div>
    </Modal>
  )
}

export default SignUpModalV2
